<template>
    <b-card
        no-body
        header-bg-variant="bluenavy"
        header="Attività Sportiva Regionale/Provinciale"
        header-text-variant="white"
        header-tag="h2"
        border-variant="bluenavy"
    >
        <template #header>
            <div style="margin: -2rem -2.25rem; padding: 2rem 2.25rem;">
                <div class="row">
                    <div class="col-md-10">
                        <h2>Attività Sportiva Regionale/Provinciale: {{event.name}}</h2>
                    </div>
                    <div class="col-md-2">
                        <button type="button" class="btn btn-block btn-primary font-weight-bolder" @click.prevent="goBack"><i class="fas fa-arrow-left"></i> Indietro</button>
                    </div>
                </div>
            </div>
        </template>
        <b-card-text>
            <b-alert show variant="secondary" dismissible>
                <h4 class="alert-heading text-center">Inserisci e gestisci le gare per l'Attività Sportiva Regionale/Provinciale: {{event.name}}</h4>
                <p>
                    Dopo aver inserito la gara, ti ricordiamo di cliccare sul bottone <i class="fas fa-sign-in-alt text-info"></i> per inserire le associazioni partecipanti.
                </p>
            </b-alert>

            <b-alert show variant="danger" dismissible>
                <p>Attenzione, l'eliminazione delle gare e dei partecipanti comporterà anche l’eliminazione dal Registro CONI.</p>
                <p v-if="$store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale'">Utilizzare l'azione cestino <i class="fas fa-trash-alt text-white"></i> e i pulsanti delle operazioni massive con cautela.</p>
                <p v-if="$store.getters.currentUser.usertype === 'Comitato'">Utilizzare l'azione cestino <i class="fas fa-trash-alt text-white"></i> con cautela.</p>
            </b-alert>
        </b-card-text>

        <SmartTable ref="regionalsportingcompetitions" url="/eps/regional-sporting-competition/index" :is_dialog="true"
                    base_url="/eps/regional-sporting-competition" base_path="/" :fixed_filters="[{ name: 'regional', filter: true }, { name: 'idevent', filter: $route.params.id }]" v-on:close-modal="closeModal">
            <template v-slot:title>
                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Gestione Gare</span></h2>
            </template>

            <template v-slot:title-actions="slotProps">
                <div v-if="slotProps.values.top_actions.length" class="card-toolbar">
                    <a v-for="(action, index) in slotProps.values.top_actions" href="#" :class="'mt-2 btn btn-' + action.class + ' font-weight-bolder mr-3'" @click.prevent="slotProps.values.doAction(action)">
                        <i v-if="action.icon" :class="action.icon"></i>
                        {{action.label}}
                    </a>

                    <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="sendSelectedCompetitions('regionalsportingcompetitions')">
                        <i class="fas fa-paper-plane"></i>
                        Invia Gare Selezionate
                    </a>

                    <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-danger font-weight-bolder mr-3" @click.prevent="removeSelectedCompetitions('regionalsportingcompetitions')">
                        <i class="fas fa-trash-alt"></i>
                        Rimuovi Gare Selezionate
                    </a>

                    <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="sendSelectedCompetitors('regionalsportingcompetitions')">
                        <i class="fas fa-paper-plane"></i>
                        Invia Partecipanti Selezionati
                    </a>

                    <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-danger font-weight-bolder" @click.prevent="removeSelectedCompetitors('regionalsportingcompetitions')">
                        <i class="fas fa-trash-alt"></i>
                        Rimuovi Partecipanti Selezionati
                    </a>
                </div>
            </template>

            <template v-slot:td-conierrorstate="slotProps">
                <td class="text-center">
                    <div v-if="typeof slotProps.values !== 'undefined'">
                        <a v-if="slotProps.values.item[slotProps.values.field.name] === 'unknown'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-info text-white text-hover-white" style="font-size: 0.9em;" @click.prevent="goMonitoraggio(5)"><i class="fas fa-hourglass-start text-white"></i> Creato</a>
                        <a v-if="slotProps.values.item[slotProps.values.field.name] === 'partial'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-primary text-white text-hover-white" @click.prevent="goMonitoraggio(5)"><i class="fas fa-paper-plane text-white"></i> Invio Parziale</a>
                        <a v-if="slotProps.values.item[slotProps.values.field.name] === 'success'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-success text-white text-hover-white" @click.prevent="goMonitoraggio(5)"><i class="fas fa-check text-white"></i> Inviato il {{slotProps.values.item.conisenddate}}</a>

                        <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="goMonitoraggio(5)"><i class="fa fa-times text-white"></i> Errore Gen.</a>
                        <a v-if="slotProps.values.item[slotProps.values.field.name] === 'mincompetitions'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="goMonitoraggio(5)"><i class="fas fa-less-than text-white"></i> Min. Gare</a>
                        <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_no_competitors_found'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="goMonitoraggio(5)"><i class="fas fa-user-alt-slash text-white"></i></i> Nessun. Part.</a>
                        <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_no_competitors_sent'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="goMonitoraggio(5)"><i class="fas fa-people-arrows text-white"></i></i> Ness. Part. Inv.</a>
                        <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_competitors_partial'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="goMonitoraggio(5)"><i class="fas fa-user-friends text-white"></i></i> Inv. Parz. Part.</a>
                    </div>
                </td>
            </template>

            <template v-slot:item-actions="slotProps">
                <td v-if="(typeof slotProps.values.item.actions !== 'undefined') && slotProps.values.item.actions.length" class="text-nowrap pr-0 text-center">
                    <a href="#" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Gestione Partecipanti" alt="Gestione Partecipanti" @click.prevent="$router.push('/eps-subscription/competition/' + slotProps.values.item.id)">
                        <i class="fas fa-sign-in-alt"></i>
                    </a>

                    <template v-for="(action, j) in slotProps.values.item.actions">
                        <a v-if="typeof action.is_external === 'undefined' || !action.is_external" href="javascript:void(0);" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label" @click.prevent="slotProps.values.doItemAction(action, slotProps.values.item['id'])">
                            <i :class="action.icon"></i>
                        </a>

                        <a v-else :href="slotProps.values.appendToken(action.url)" target="_blank" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label">
                            <i :class="action.icon"></i>
                        </a>
                    </template>
                </td>
            </template>
        </SmartTable>
    </b-card>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
 import ApiService from "@/core/services/api.service";
 import SmartTable from "@/view/components/SmartTable.vue";
 import Swal from 'sweetalert2';

 export default {
     data() {
         return {
             event: {
                 name: '',
             },
         }
     },

     components: {
         SmartTable,
     },

     mounted() {
         this.$watch(
             () => {
                 let fields = this.$refs.regionalsportingcompetitions.componentFields;

                 if (fields.length)
                 {
                     if (typeof this.$refs.regionalsportingcompetitions.$refs.modalcomponent === "undefined" || typeof this.$refs.regionalsportingcompetitions.$refs.modalcomponent.form_type === "undefined" ||  this.$refs.regionalsportingcompetitions.$refs.modalcomponent.form_type === 'edit' || this.$refs.regionalsportingcompetitions.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.regionalsportingcompetitions.componentValuesloaded === null || this.$refs.regionalsportingcompetitions.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.regionalsportingcompetitions.$refs.modalcomponent, 'placeidregion');
                     if (field) {
                         let value = this.getFieldValue(field);

                         if (value && value.id) return value.id;

                         return null;
                     }
                     else return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldProvstate = null;
                 let fieldProvstateValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldProvstate = this.findField(this.$refs.regionalsportingcompetitions.$refs.modalcomponent, 'placeidprovstate');
                         fieldProvstateValue = JSON.parse(JSON.stringify(fieldProvstate.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('national-sporting-event', this.$refs.regionalsportingcompetitions.$refs.modalcomponent, 'provstates', 'idregion', value.id, 'placeidprovstate',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                provstatecode: 'Seleziona una provincia',
                                            });
                                        },
                                        function(options) {
                                            fieldProvstate = vm.findField(vm.$refs.regionalsportingcompetitions.$refs.modalcomponent, 'placeidprovstate');

                                            if (fieldProvstate) {
                                                vm.$set(fieldProvstate, 'filter_options', options);

                                                if (typeof fieldProvstate.value !== "undefined") {
                                                    if (fieldProvstateValue !== null) {
                                                        fieldProvstate.value = fieldProvstateValue;
                                                    }
                                                    else {
                                                        fieldProvstate.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.regionalsportingcompetitions.componentFields;

                 if (fields.length)
                 {
                     if (typeof this.$refs.regionalsportingcompetitions.$refs.modalcomponent === "undefined" || typeof this.$refs.regionalsportingcompetitions.$refs.modalcomponent.form_type === "undefined" ||  this.$refs.regionalsportingcompetitions.$refs.modalcomponent.form_type === 'edit' || this.$refs.regionalsportingcompetitions.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.regionalsportingcompetitions.componentValuesloaded === null || this.$refs.regionalsportingcompetitions.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.regionalsportingcompetitions.$refs.modalcomponent, 'placeidprovstate');
                     let value = this.getFieldValue(field);

                     if (typeof value !== "undefined" && value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldCity = null;
                 let fieldCityValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldCity = this.findField(this.$refs.regionalsportingcompetitions.$refs.modalcomponent, 'placeidcity');
                         fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('national-sporting-event', this.$refs.regionalsportingcompetitions.$refs.modalcomponent, 'cities', 'idprovstate', value.id, 'placeidcity',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                cityname: 'Seleziona una città',
                                            });
                                        },
                                        function (options) {
                                            fieldCity = vm.findField(vm.$refs.regionalsportingcompetitions.$refs.modalcomponent, 'placeidcity');

                                            if (fieldCity) {
                                                vm.$set(fieldCity, 'filter_options', options);

                                                if (typeof fieldCity.value !== "undefined") {
                                                    if (fieldCityValue !== null) {
                                                        fieldCity.value = fieldCityValue;
                                                    }
                                                    else {
                                                        fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.regionalsportingcompetitions.componentFields;

                 if (fields.length)
                 {
                     if (typeof this.$refs.regionalsportingcompetitions.$refs.modalcomponent === "undefined" || typeof this.$refs.regionalsportingcompetitions.$refs.modalcomponent.form_type === "undefined" ||  this.$refs.regionalsportingcompetitions.$refs.modalcomponent.form_type === 'edit' || this.$refs.regionalsportingcompetitions.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.regionalsportingcompetitions.componentValuesloaded === null || this.$refs.regionalsportingcompetitions.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.regionalsportingcompetitions.$refs.modalcomponent, 'regions');
                     let value = this.getFieldValue(field);

                     if (value && value.length) {
                         return value;
                     }

                     return null;
                 }
                 else return null;
             },
             (regions) => {
                 let fieldProvstates = this.findField(this.$refs.regionalsportingcompetitions.$refs.modalcomponent, 'provstates');
                 let fieldProvstatesValue = [];
                 if (fieldProvstates && fieldProvstates.value && fieldProvstates.value.length) fieldProvstatesValue = JSON.parse(JSON.stringify(fieldProvstates.value));

                 if (regions) {
                     let idsregion = [];

                     for (let i in regions) {
                         idsregion.push(regions[i].id);
                     }

                     let vm = this;
                     if (fieldProvstates) {
                         this.loadOptions('regional-sporting-competition', this.$refs.regionalsportingcompetitions.$refs.modalcomponent, 'provstates', 'regions', encodeURI(idsregion), 'provstates',
                                          function(options) {
                                          },
                                          function(options) {
                                              if (fieldProvstates) {
                                                  vm.$set(fieldProvstates, 'filter_options', options);
                                                  vm.$set(fieldProvstates, 'value', []);

                                                  if (fieldProvstatesValue !== null) {

                                                      for (let i in options)
                                                      {
                                                          for (let j in fieldProvstatesValue) {
                                                              let option = options[i].provstates.find(function(item) {
                                                                  return item.id === fieldProvstatesValue[j].id;
                                                              });
                                                              if (option) {
                                                                  fieldProvstates.value.push(JSON.parse(JSON.stringify(option)));
                                                              }
                                                          }
                                                      }
                                                  }
                                              }
                                          }
                         );
                     }
                 }
             }
         );

         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Portale EPS", route: "/eps-portal" },
             { title: "Attività Sportiva Regionale/Provinciale", route: "/eps-portal" },
             { title: "Gestione Gare", route: "" },
         ]);
     },

     created() {
         let idevent = this.$route.params.id;

         let url = '/eps/regional-sporting-competition/load-options?type=eventname&idevent=' + idevent;

         ApiService.query(url)
                   .then((response) => {
                       this.event.name = response.data;
                   })
                   .catch((error) => {
                       console.log(error);
                   })
     },

     methods: {
         goBack() {
             window.history.go(-1);
         },

         closeModal() {
             this.$refs.regionalsportingcompetitions.refresh();
         },

         findField(obj, name) {
             if (typeof obj !== "undefined" && obj && typeof obj.fields !== "undefined" && obj.fields) {
                 return obj.fields.find(item => item.name === name);
             }

             return null;
         },

         getFieldValue(field) {
             if (field) {
                 let value = typeof field.value !== "undefined" ? field.value : null;

                 if (value !== null) {
                     return (JSON.parse(JSON.stringify(value)));
                 }
             }

             return null;
         },

         setFieldVisible(obj, name, visible) {
             if (typeof obj.fields !== "undefined") {
                 let fieldIndex = obj.fields.findIndex(item => item.name === name);
                 if (fieldIndex) {
                     if (typeof obj.fields !== "undefined" && typeof obj.fields[fieldIndex] !== "undefined") this.$set(obj.fields[fieldIndex], 'is_visible_for_store', visible);
                     if (typeof obj.fields !== "undefined" && typeof obj.fields[fieldIndex] !== "undefined") this.$set(obj.fields[fieldIndex], 'is_visible_for_update', visible);
                 }
             }
         },

         setFieldAttribute(obj, name, attribute, value) {
             let fieldIndex = obj.fields.findIndex(item => item.name === name);
             if (fieldIndex) {
                 this.$set(obj.fields[fieldIndex], attribute, value);
             }
         },

         loadOptions(baseurl, obj, type, parameterName, parameterValue, fieldName, callbackEmptyOption, callbackOptions = null) {
             let url = '/eps/' + baseurl + '/load-options?type=' + type + '&' + parameterName + '=' + parameterValue;

             ApiService.query(url)
                       .then((response) => {
                           let options = response.data;

                           if (typeof options === "undefined") options = [];

                           callbackEmptyOption(options);

                           if (callbackOptions !== null) {
                               callbackOptions(options);
                           }
                           else {
                               this.setFieldAttribute(obj, fieldName, 'filter_options', options);
                           }
                       })
                       .catch((error) => {
                           console.log(error);

                           let options = [];

                           callbackEmptyOption(options);

                           if (callbackOptions !== null) {
                               callbackOptions(options);
                           }
                           else {
                               this.setFieldAttribute(obj, fieldName, 'filter_options', options);
                           }
                       })
         },

         getApiUrl() {
             return ApiService.getApiUrl();
         },

         sendSelectedCompetitions(table) {
             Swal.fire({
                 title: 'Conferma invio gare selezionate',
                 text: "Sei sicuro di voler inviare le gare selezionate?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let competitions = [];
                     for (let i in this.$refs[table].items) {
                         if (this.$refs[table].items[i].checked) competitions.push({ idcompetition: this.$refs[table].items[i].id } );
                     }

                     if (competitions.length) {
                         let url = '/epsconimonitoring/send-selected-competitions';
                         let data = {
                             competitions: competitions,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Gare inviate correttamente', {
                                               title: 'Gare inviate correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           for (let i in this.$refs[table].items) {
                                               if (this.$refs[table].items[i].checked) this.$refs[table].items[i].checked = false;
                                           }
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nell\'invio delle gare.', {
                                               title: 'Errore nell\'invio delle gare',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessuna gara selezionata',
                             text: "Per effettuare l\'invio selezionare almeno una gara",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         removeSelectedCompetitions(table) {
             Swal.fire({
                 title: 'Conferma rimozione gare selezionate',
                 text: "Sei sicuro di voler rimuovere le gare selezionate?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let competitions = [];
                     for (let i in this.$refs[table].items) {
                         if (this.$refs[table].items[i].checked) competitions.push({ idcompetition: this.$refs[table].items[i].id } );
                     }

                     if (competitions.length) {
                         let url = '/epsconimonitoring/remove-selected-competitions';
                         let data = {
                             competitions: competitions,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Gare rimosse correttamente', {
                                               title: 'Gare rimosse correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           for (let i in this.$refs[table].items) {
                                               if (this.$refs[table].items[i].checked) this.$refs[table].items[i].checked = false;
                                           }
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nella rimozione delle gare.', {
                                               title: 'Errore nella rimozione delle gare',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessuna gara selezionata',
                             text: "Per effettuare la rimozione selezionare almeno una gara",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         sendSelectedCompetitors(table) {
             Swal.fire({
                 title: 'Conferma invio partecipanti per le gare selezionate',
                 text: "Sei sicuro di voler inviare i partecipanti per le gare selezionate?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let competitions = [];
                     for (let i in this.$refs[table].items) {
                         if (this.$refs[table].items[i].checked) competitions.push({ idcompetition: this.$refs[table].items[i].id } );
                     }

                     if (competitions.length) {
                         let url = '/epsconimonitoring/send-selected-competitors-competitions';
                         let data = {
                             competitions: competitions,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Partecipanti inviati correttamente', {
                                               title: 'Partecipanti inviati correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           for (let i in this.$refs[table].items) {
                                               if (this.$refs[table].items[i].checked) this.$refs[table].items[i].checked = false;
                                           }
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nell\'invio dei partecipanti.', {
                                               title: 'Errore nell\'invio dei partecipanti',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessuna gara selezionata',
                             text: "Per effettuare l\'invio dei partecipanti selezionare almeno una gara",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         removeSelectedCompetitors(table) {
             Swal.fire({
                 title: 'Conferma rimozione partecipanti per le gare selezionate',
                 text: "Sei sicuro di voler rimuovere i partecipanti per le gare selezionate?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let competitions = [];
                     for (let i in this.$refs[table].items) {
                         if (this.$refs[table].items[i].checked) competitions.push({ idcompetition: this.$refs[table].items[i].id } );
                     }

                     if (competitions.length) {
                         let url = '/epsconimonitoring/remove-selected-competitors-competitions';
                         let data = {
                             competitions: competitions,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Partecipanti rimossi correttamente', {
                                               title: 'Partecipanti rimossi correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           for (let i in this.$refs[table].items) {
                                               if (this.$refs[table].items[i].checked) this.$refs[table].items[i].checked = false;
                                           }
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nella rimozione dei partecipanti.', {
                                               title: 'Errore nella rimozione dei partecipanti',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessuna gara selezionata',
                             text: "Per effettuare la rimozione dei partecipanti selezionare almeno una gara",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         goMonitoraggio(tabIndex) {
             localStorage.setItem('currentEpsTabIndex', tabIndex);
             this.goBack();
         },
     },
 };
</script>

<style>
 ul.nav-tabs .nav-item a.nav-link {
     height: 100%;
 }
</style>
